
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import { ResponseDataStringArray as rdsa } from "@/interfaces";
import * as types from "@/store/types";
import axios, { AxiosError, AxiosResponse } from "axios";
import authHeader from "@/services/auth/auth-header";
@Component({
    components: {
        FilterFormPanelBase: () =>
            import("@/components/layout/FilterFormPanelBase.vue"),
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        CalendarRangeInput: () =>
            import("@/components/layout/CalendarRangeInput.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class Prices extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
    role = Vue.prototype.$user.get().role;
    userIdentifier?: number;
    companies: string[] = [];
    company = "";
    clients: string[] = [];
    client = "";
    installations: string[] = [];
    install = "";
    file: any;
    invoicePDF: any;
    show = false;
    dates: string[] = [];

    mounted() {
        this.fetchUser();
    }

    submitFile() {
        const formData = new FormData();
        const url =
            "http://localhost:5000/api/ingestor/CSV?client=" +
            this.client.split(" - ")[0] +
            "&install=" +
            this.install.split(" - ")[0];
        formData.append("file", this.file);
        axios
            .post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    ...authHeader()
                },
                responseType: "blob"
            })
            .then(response => {
                this.show = false;
                // const downloadUrl = window.URL.createObjectURL(
                //     new Blob([response.data])
                // );
                // this.invoicePDF = downloadUrl;
                // const link = document.createElement("a");
                // link.href = this.invoicePDF;
                // link.setAttribute("download", "file.pdf");
                // document.body.appendChild(link);
                // link.click();
                // link.remove();
            })
            .catch(function() {
                console.log("FAILURE!!");
            });
    }

    save() {
        this.show = true;
    }

    // handleFileUpload() {
    //     if (this.$refs.file) {
    //         this.file = this.$refs.file.files[0];
    //         console.log(">>>> 1st element in files array >>>> ", this.file);
    //     }
    // }

    async fetchUser() {
        if (this.role == "client") {
            this.setProgressBar(true);
            try {
                const userClientResponse = await this.$api.SQLuserClient<rdsa>(
                    this.userIdentifier
                );
                if (userClientResponse.ok) {
                    this.company = userClientResponse.data[0];
                    this.client = userClientResponse.data[1];
                    this.fetchInstallation();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            } finally {
                this.setProgressBar(false);
            }
        } else if (this.role == "admin") {
            this.fetchCompany();
        } else {
            console.log("Role undefined");
        }
    }

    async fetchCompany() {
        this.setProgressBar(true);
        try {
            const companyResponse = await this.$api.SQLcompany<rdsa>();
            if (companyResponse.ok) {
                this.companies = companyResponse.data;
                this.company = this.companies[0];
                this.fetchClient();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async fetchClient() {
        this.setProgressBar(true);
        try {
            const clientResponse = await this.$api.SQLclient<rdsa>(
                this.companies[0].split(" - ")[0]
            );
            if (clientResponse.ok) {
                this.clients = clientResponse.data;
                this.client = this.clients[0];
                this.fetchInstallation();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async fetchInstallation() {
        this.setProgressBar(true);
        try {
            const installResponse = await this.$api.SQLelementoAhorro<rdsa>(
                this.client.split(" - ")[0]
            );
            if (installResponse.ok) {
                this.installations = installResponse.data;
                this.install = this.installations[0];
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }
}
